
import React, { FC } from 'react';

interface IProps {
  color?: string;
}

export const LocationIcon: FC<IProps> = ({ color = '#ffffff' }: IProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.1199 10.3099C15.1199 10.927 14.9369 11.5302 14.5941 12.0433C14.2512 12.5564 13.764 12.9563 13.1939 13.1924C12.6238 13.4286 11.9964 13.4904 11.3912 13.37C10.786 13.2496 10.2301 12.9525 9.79371 12.5161C9.35737 12.0798 9.06022 11.5238 8.93983 10.9186C8.81945 10.3134 8.88123 9.68607 9.11738 9.11597C9.35353 8.54586 9.75342 8.05859 10.2665 7.71576C10.7796 7.37293 11.3828 7.18994 11.9999 7.18994C12.8274 7.18994 13.6209 7.51865 14.2061 8.10377C14.7912 8.68888 15.1199 9.48247 15.1199 10.3099V10.3099Z" stroke={color} stroke-width="1.5" />
      <path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C14.632 21.4735 13.3397 21.9952 11.995 21.9952C10.6502 21.9952 9.35788 21.4735 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" stroke={color} stroke-width="1.5" />
    </svg>
  );
};
