import React, { FC } from 'react';
import ArrowDownIcon from './ArrowDown.svg';
import SVGIcon from '../SVGIcon/SVGIcon';

const ArrowDown: FC = () => (
  <div style={{ marginLeft: '6pt' }}>
    <SVGIcon src={ArrowDownIcon} />
  </div>
);

export default ArrowDown;
