/*
 * PendingOrdersHistoryPageActions Messages
 *
 * This contains all the text for the PendingOrdersHistoryPageActions container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.PendingOrdersHistoryPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Lịch sử đơn hàng đang chờ duyệt',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'Nhà cung cấp',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Sản phẩm',
  },
  notes: {
    id: `${scope}.notes`,
    defaultMessage: 'Ghi chú',
  },
  by: {
    id: `${scope}.by`,
    defaultMessage: 'Người mua',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Trạng thái',
  },
  allStatus: {
    id: `${scope}.allStatus`,
    defaultMessage: 'Tất cả trạng thái',
  },
  selectStatus: {
    id: `${scope}.selectStatus`,
    defaultMessage: 'Chọn trạng thái',
  },
  viewDetails: {
    id: `${scope}.viewDetails`,
    defaultMessage: 'Xem chi tiết',
  },
  PENDING_APPROVAL: {
    id: `${scope}.status.PENDING_APPROVAL`,
    defaultMessage: 'Đang chờ duyệt',
  },
  APPROVAL_DISAPPROVED: {
    id: `${scope}.status.APPROVAL_DISAPPROVED`,
    defaultMessage: 'Không duyệt',
  },
  orderTime: {
    id: `${scope}.orderTime`,
    defaultMessage: 'Ngày đặt hàng',
  },
  deliveryTime: {
    id: `${scope}.deliveryTime`,
    defaultMessage: 'Ngày giao hàng',
  },
  declineReason: {
    id: `${scope}.declineReason`,
    defaultMessage: 'Lý do từ chối',
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Tổng',
  },
  deliveryToday: {
    id: `${scope}.deliveryToday`,
    defaultMessage: 'Giao hàng hôm nay',
  },
  ordersToday: {
    id: `${scope}.ordersToday`,
    defaultMessage: 'Đơn hàng hôm nay',
  },
  pendingApprovalOrders: {
    id: `${scope}.pendingApprovalOrders`,
    defaultMessage: 'Đơn hàng đang chờ duyệt',
  },
  totalPurchase: {
    id: `${scope}.totalPurchase`,
    defaultMessage: 'Giá trị đã mua',
  },
  pendingOrderHistory: {
    id: `${scope}.pendingOrderHistory`,
    defaultMessage: 'Lịch sử đơn chờ duyệt',
  },
  allSupplier: {
    id: `${scope}.allSupplier`,
    defaultMessage: 'Tất cả nhà cung cấp',
  },
  selectSupplier: {
    id: `${scope}.selectSupplier`,
    defaultMessage: 'Chọn nhà cung cấp',
  },
  orderDate: {
    id: `${scope}.orderDate`,
    defaultMessage: 'Ngày đặt hàng',
  },
  deliveryDate: {
    id: `${scope}.deliveryDate`,
    defaultMessage: 'Ngày giao hàng',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Xoá',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Đồng ý',
  },
  hasNoOrders: {
    id: `${scope}.hasNoOrders`,
    defaultMessage: 'Không có đơn hàng nào',
  },
  totalItems: {
    id: `${scope}.totalItems`,
    defaultMessage: 'Tổng số sản phẩm',
  },
  editOrder: {
    id: `${scope}.editOrder`,
    defaultMessage: 'Thay đổi đơn hàng',
  },
  actualQuantity: {
    id: `${scope}.actualQuantity`,
    defaultMessage: 'Số lượng thực tế',
  },
  editQuantity: {
    id: `${scope}.editQuantity`,
    defaultMessage: 'Cập nhật số lượng',
  },
  orderQuantity: {
    id: `${scope}.orderQuantity`,
    defaultMessage: 'Số lượng đã đặt',
  },
  acceptedQuantity: {
    id: `${scope}.acceptedQuantity`,
    defaultMessage: 'Số lượng đã nhận',
  },
  creator: {
    id: `${scope}.creator`,
    defaultMessage: 'Người đặt hàng',
  },
});
