// import ChatSupport from 'components/ChatSupport';
import LoadingIndicator from 'components/LoadingIndicator';
import Notification from 'components/Notification';
import { Viewport } from 'components/ViewportProvider';
// import FavoriteCategoryNav from 'containers/FavoriteCategoryNav';
import { IMainLayoutDispatchProps, IMainLayoutStateProps } from 'containers/MainLayout/types';
import { onMessageListener } from '../firebase';
import { isSupported } from 'firebase/messaging';
import React, { FC, ReactElement, Suspense, lazy, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, RouteProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import media from 'utils/mediaStyle';
import BreadCrumb from './BreadCrumb';
import { ErrorBoundary } from './CategoryNav/ErrorBoundary';
import TopNav from './MainNav/TopNav';
import TabPages from './TabPages';
import messages from 'utils/messages';
import translations from 'translations';
import TagsNavBar from './TagsNavBar';
import { pathnameChecker } from 'utils/url';
import AlcoholPopup from './AlcoholPopup/index';

const Content = styled.section<any>`
  margin-left: 0;
  position: relative;
  flex-shrink: 1;
  flex-grow: 1;
  margin-bottom: 16px;
  ${media.md`
    padding: 0 10pt;
  `};
  ${media.lg`
    ${({ overflowVisible }) =>
      !overflowVisible &&
      `overflow-x: hidden;
   `}
  `};

  padding-top: 0;
  transition: margin-left 0.3s ease-in-out;
  background: #f9fafc;
  min-height: calc(100vh - 160pt);
  ${({ isHomePage }) =>
    isHomePage &&
    `
  @media (max-width: 576px) {
    margin-bottom: 0px;
  }
  `}
  ${({ addMarginTop }) =>
    addMarginTop &&
    `
  margin-top: 80.5pt;
`}
`;

const MainContent = styled.div`
  max-width: 1200px;
  min-height: calc(100svh - 124px);
  margin: auto;
  position: relative;
  display: flex;
  @media (max-width: 1024px) {
    display: block;
  }
  ${({ isHomePage }) =>
    isHomePage &&
    `
  @media (max-width: 767px) {
    &:before {
      content: '';
      position: fixed;
      width: 100%;
      height: 400px;
      top: 0;
      left: 0;
      background: rgb(76, 175, 80);
      z-index: -1;
    }
  }
  `}
`;

const FooterWrapper = styled.div`
  background: #ffffff;
  padding: 0 10pt;
  min-height: 780px;
  width: 100%;
  @media (max-width: 1024px) {
    border-bottom: 72px solid transparent;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
`;

const TopElementsWrapper = styled.div`
  & > div:first-child {
    margin-top: 89.5pt;
  }
`;

type Props = IMainLayoutStateProps & IMainLayoutDispatchProps & RouteProps & RouteComponentProps;

const pathAbleDisplaySideNav = [
  '/orders',
  '/orders-pending-approval',
  '/pending-orders-history',
  '/item-analysis',
  '/company-info',
  '/store-info',
  '/user-management',
  '/group-management',
  '/overview',
  '/account-info',
  '/account-mobile',
  '/user-details',
  '/groups',
];

const pathNotDisplayBreadCrumb = [
  '/',
  '/account-mobile',
  '/thuong-hieu-kamereo',
  '/thuong-hieu',
  '/he-thong-phan-phoi',
  '/nguyen-lieu-nhat-ban-gyomu-japan',
  '/not-authorized',
  '/not-found',
];

const ChatSupport = lazy(() => import(/* webpackPreload: true */ 'components/ChatSupport'));
const FooterComponent = lazy(() => import('components/Footer'));
const LazyModal = lazy(() => import('antd').then((module) => ({ default: module.Modal })));
const SideNavNewDesign = lazy(() => import('components/MainNav/SideNavNewDesign'));
const FavoriteCategoryNav = lazy(() => import('containers/FavoriteCategoryNav'));
const MainLayout: FC<Props> = (props) => {
  const {
    mainLayout: {
      buyer,
      store,
      sideNav,
      user,
      cart,
      notification,
      categories,
      hasUnreadNotification,
      isOpenAlcoholPopup,
      isUnder18,
    },
    children,
    setLang,
    signout,
    toggleSideNav,
    isPenddingApproval,
    countPendingOrder,
    registerNotificationToken,
    location,
    setSearchProductResults,
    clearSearchProductResults,
    fetchBuyerWebVersion,
    buyerWebVersion,
  } = props;
  const FooterRef = useRef<ReactElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const userPermissions = useMemo(() => {
    const permissions = (user.permissions || []).map((permission) => permission.action);
    (user.userGroups || []).forEach((group) => {
      group.permissions.forEach((permission) => {
        if (permissions.indexOf(permission.action) === -1) {
          permissions.push(permission.action);
        }
      });
    });
    return permissions;
  }, [user.permissions, user.userGroups]);
  useEffect(() => {
    (async () => {
      if (await isSupported()) {
        onMessageListener(buyer?.id, store?.id);
      }
    })();
    const sixHours = 21600000;
    const intervalId = setInterval(() => {
      fetchBuyerWebVersion();
    }, sixHours);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    if (buyerWebVersion) {
      const buyerVersionStorage = localStorage.getItem('buyer-version');
      if (!buyerVersionStorage) {
        localStorage.setItem('buyer-version', buyerWebVersion);
        return;
      }
      if (buyerVersionStorage !== buyerWebVersion) {
        setOpenModal(true);
      }
    }
  }, [buyerWebVersion]);
  return (
    <ErrorBoundary>
      <ErrorBoundary>
        <TopNav
          cart={cart}
          user={user}
          store={store}
          buyer={buyer}
          permissions={userPermissions}
          onToggle={toggleSideNav}
          onSignout={signout}
          onSetLang={setLang}
          opened={sideNav}
          categories={categories}
          hasUnreadNotification={hasUnreadNotification}
          setSearchProductResults={setSearchProductResults}
          clearSearchProductResults={clearSearchProductResults}
        />
      </ErrorBoundary>
      <Viewport.Consumer>
        {({ width }) => (
          <TopElementsWrapper>
            {width > 991 &&
              location.pathname !== '/' &&
              (pathnameChecker.isMarketRoutes(location.pathname) ||
                pathnameChecker.isProductTagPage(location.pathname.slice(1))) && (
                <div style={{ marginTop: '89.5pt' }}>
                  <TagsNavBar />
                </div>
              )}
            {!pathNotDisplayBreadCrumb.includes(location.pathname) && <BreadCrumb />}
          </TopElementsWrapper>
        )}
      </Viewport.Consumer>
      <Viewport.Consumer>
        {({ width }) => (
          <MainContent isHomePage={location.pathname === '/'}>
            {location.pathname === '/favorite' && width > 1024 && (
              <ErrorBoundary>
                <Suspense fallback={<LoadingIndicator />}>
                  <FavoriteCategoryNav />
                </Suspense>
              </ErrorBoundary>
            )}
            {pathAbleDisplaySideNav.includes(
              location.pathname.lastIndexOf('/') === 0
                ? location.pathname
                : location.pathname.substring(0, location.pathname.lastIndexOf('/')),
            ) &&
              width > 1024 && (
                <ErrorBoundary>
                  <Suspense fallback={<LoadingIndicator />}>
                    <SideNavNewDesign
                      opened={sideNav}
                      permissions={userPermissions}
                      store={store}
                      user={user}
                      onToggle={toggleSideNav}
                      isPenddingApproval={isPenddingApproval}
                      countPendingOrder={countPendingOrder}
                    />
                  </Suspense>
                </ErrorBoundary>
              )}
            <Content
              addMarginTop={location.pathname === '/' || location.pathname === '/account-mobile'}
              isHomePage={location.pathname === '/'}
              overflowVisible={
                location.pathname === '/thuong-hieu-kamereo' ||
                location.pathname === '/nguyen-lieu-nhat-ban-gyomu-japan'
              }
            >
              {pathAbleDisplaySideNav.includes(location.pathname) && (
                <ErrorBoundary>
                  <TabPages permissions={userPermissions} isPendingApproval={isPenddingApproval} />
                </ErrorBoundary>
              )}
              <ErrorBoundary>{children}</ErrorBoundary>
            </Content>
          </MainContent>
        )}
      </Viewport.Consumer>
      <Suspense fallback={<LoadingIndicator />}>
        <ChatSupport />
      </Suspense>
      <FooterWrapper ref={FooterRef}>
        <Suspense fallback={<LoadingIndicator />}>
          <FooterComponent />
        </Suspense>
      </FooterWrapper>
      <Notification
        userId={user.id}
        notificationId={notification.id}
        registerNotificationToken={registerNotificationToken}
      />
      {openModal && (
        <Suspense fallback={<LoadingIndicator />}>
          <LazyModal
            title={translations(messages.notice)}
            open={openModal}
            onOk={() => {
              localStorage.setItem('buyer-version', buyerWebVersion || '');
              window.location.reload();
              setOpenModal(false);
            }}
            okText={translations(messages.yes)}
            cancelText={translations(messages.no)}
            onCancel={() => setOpenModal(false)}
          >
            <FormattedMessage {...messages.reloadPageMessage} />
          </LazyModal>
        </Suspense>
      )}
      <AlcoholPopup open={isOpenAlcoholPopup} isUnder18={isUnder18} />
    </ErrorBoundary>
  );
};

export default withRouter(MainLayout);
