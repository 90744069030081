import { notification } from 'antd';
import { requestFCMToken } from '../../firebase';
import { FC, memo, useCallback, useEffect } from 'react';
import { isSupported } from 'firebase/messaging';
import * as Sentry from '@sentry/browser';
import { FirebaseError } from 'firebase/app';
import translations from 'translations';
import utilsMessages from 'utils/messages';
import useLocalStorage from 'hooks/useLocalStorage';
import { NotificationRegisterInput, Platform, TokenType } from 'types/schema';

const PERMISSION_BLOCKED = 'messaging/permission-blocked';

interface IProps {
  userId: string;
  notificationId?: string;
  registerNotificationToken: (input: NotificationRegisterInput) => void;
}

const Notification: FC<IProps> = (props) => {
  const { userId, notificationId, registerNotificationToken } = props;
  const [notificationReminder, setNotificationReminder] = useLocalStorage('notificationReminder', false);
  const initNotification = useCallback(async () => {
    try {
      const token = await requestFCMToken();
      const input: NotificationRegisterInput = { token: token, tokenType: TokenType.Fcm, platform: Platform.Web };
      registerNotificationToken(input);
    } catch (error) {
      if ((error as FirebaseError).code === PERMISSION_BLOCKED) {
        if (!notificationReminder) {
          notification.open({
            className: !!(window as any).chrome ? 'speech-bubble' : '',
            placement: 'topLeft',
            message: translations(utilsMessages.remindNotificationPermission),
          });
          setNotificationReminder(true);
        }
        Sentry.captureMessage('The notification permission was not granted and blocked instead');
      } else {
        Sentry.captureException(error, { user: { id: userId } });
      }
    }
  }, [userId, registerNotificationToken]);

  useEffect(() => {
    (async () => {
      if ((await isSupported()) && !notificationId && userId) {
        initNotification();
      }
    })();
  }, [userId]);

  return null;
};

const propsAreEqual = (prevProps: IProps, nextProps: IProps) => {
  return prevProps.notificationId === nextProps.notificationId && prevProps.userId === nextProps.userId;
};

export default memo(Notification, propsAreEqual);
