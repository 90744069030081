

import React, { FC } from 'react';

interface IProps {
  isActive: boolean;
}

export const CategoryIcon: FC<IProps> = (props) => {
  const { isActive } = props;
  return (
    <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3021 5.23302V2.58469C13.3021 1.76219 12.9288 1.42969 12.0013 1.42969H9.64458C8.71708 1.42969 8.34375 1.76219 8.34375 2.58469V5.22719C8.34375 6.05552 8.71708 6.38219 9.64458 6.38219H12.0013C12.9288 6.38802 13.3021 6.05552 13.3021 5.23302Z" stroke={isActive ? '#4caf50': '#424242'} stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.3021 11.7942V9.43755C13.3021 8.51005 12.9288 8.13672 12.0013 8.13672H9.64458C8.71708 8.13672 8.34375 8.51005 8.34375 9.43755V11.7942C8.34375 12.7217 8.71708 13.0951 9.64458 13.0951H12.0013C12.9288 13.0951 13.3021 12.7217 13.3021 11.7942Z" stroke={isActive ? '#4caf50': '#424242'} stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.59505 5.23302V2.58469C6.59505 1.76219 6.22172 1.42969 5.29422 1.42969H2.93755C2.01005 1.42969 1.63672 1.76219 1.63672 2.58469V5.22719C1.63672 6.05552 2.01005 6.38219 2.93755 6.38219H5.29422C6.22172 6.38802 6.59505 6.05552 6.59505 5.23302Z" stroke={isActive ? '#4caf50': '#424242'} stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.59505 11.7942V9.43755C6.59505 8.51005 6.22172 8.13672 5.29422 8.13672H2.93755C2.01005 8.13672 1.63672 8.51005 1.63672 9.43755V11.7942C1.63672 12.7217 2.01005 13.0951 2.93755 13.0951H5.29422C6.22172 13.0951 6.59505 12.7217 6.59505 11.7942Z" stroke={isActive ? '#4caf50': '#424242'} stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
