import { suffixCategoriesRegexp } from 'utils/urls/constants';

const getRefinedLink = (link?: string) => {
  if (link) {
    if (link.includes(suffixCategoriesRegexp.page.codename)) {
      return link.slice(0, link.indexOf(suffixCategoriesRegexp.page.codename) - 1);
    }
    return link;
  }
  return '';
};

export default getRefinedLink;
