/*
 * UserManagementPage Messages
 *
 * This contains all the text for the UserManagementPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.UserManagementPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Đồng nghiệp',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'Tên',
  },
  groups: {
    id: `${scope}.groups`,
    defaultMessage: 'Nhóm',
  },
  permissions: {
    id: `${scope}.permissions`,
    defaultMessage: 'Quyền',
  },
  searchUser: {
    id: `${scope}.searchUser`,
    defaultMessage: 'Tìm theo tên',
  },
  filterByGroup: {
    id: `${scope}.filterByGroup`,
    defaultMessage: 'Lọc theo nhóm',
  },
  filterByPermissions: {
    id: `${scope}.filterByPermissions`,
    defaultMessage: 'Lọc theo quyền',
  },
  addNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'Thêm',
  },
  invite: {
    id: `${scope}.invite`,
    defaultMessage: 'Mời',
  },
  inviteUser: {
    id: `${scope}.inviteUser`,
    defaultMessage: 'Mời đồng sự',
  },
  congrat: {
    id: `${scope}.congrat`,
    defaultMessage: 'Lời mời đã gửi thành công!',
  },
  inviteNewUser: {
    id: `${scope}.inviteNewUser`,
    defaultMessage: 'Mời đồng nghiệp',
  },
  invitationSent: {
    id: `${scope}.invitationSent`,
    defaultMessage: 'Lời mời đã được gửi',
  },
  askColleagueToCheckMailSentence: {
    id: `${scope}.askColleagueToCheckMailSentence`,
    defaultMessage: 'Hãy nhắn đồng nghiệp kiểm tra email để truy cập vào tài khoản nhé!',
  },
  inviteFailed: {
    id: `${scope}.inviteFailed`,
    defaultMessage: 'Mời đồng nghiệp thất bại',
  },
});
