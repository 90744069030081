/*
 * DashboardPage Messages
 *
 * This contains all the text for the DashboardPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.DashboardPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Tổng quan',
  },
  welcomeKamereo: {
    id: `${scope}.welcomeKamereo`,
    defaultMessage: 'Chào mừng đến với KAMEREO!',
  },
  firstOrder: {
    id: `${scope}.firstOrder`,
    defaultMessage: 'Tạo đơn hàng đầu tiên.',
  },
  covidContent: {
    id: `${scope}.covidContent`,
    defaultMessage:
      '\nKhách hàng thân mến,\n\nChúng tôi rất tiếc phải thông báo rằng chúng tôi phát hiện nhân viên bị nghi nhiễm COVID-19 trong lần xét nghiệm nhanh vào sáng 16/8 và đã được xác nhận dương tính từ kết quả PCR. Theo hướng dẫn từ cơ quan y tế, chúng tôi tạm thời đóng cửa hoạt động cho đến khi có thông báo mới. Đồng thời xin vui lòng kiểm tra email từ chúng tôi.\n\nChân thành cám ơn sự thông cảm của quý vị cũng như đã luôn ủng hộ chúng tôi trong thời điểm khó khăn này.\n',
  },
});
