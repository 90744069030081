import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import message from './messages';
import { FormattedMessage } from 'react-intl';

const BlogItem = styled.a`
  display: block;
  padding: 8px;
  font-size: 14px;
`;
const DropdownText = styled.div`
  display: flex;
  gap: 5px;
  .anticon {
    font-size: 10px;
    margin-top: 2px;
  }
  cursor: pointer;
`;

const BlogDropDown: FC = () => {
  const lang = window.localStorage.getItem('lang') || 'vi';
  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <BlogItem
          href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'kien-thuc-kinh-doanh/' : 'business-tips/'}`}
        >
          <FormattedMessage {...message.businessTips} />
        </BlogItem>
      ),
    },
    {
      key: 2,
      label: (
        <BlogItem
          href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'am-thuc-doi-song/' : 'food-and-lifestyle/'}`}
        >
          <FormattedMessage {...message.foodAndLifeStyle} />
        </BlogItem>
      ),
    },
    {
      key: 3,
      label: (
        <BlogItem
          href={`https://kamereo.vn/blog/${lang}/${
            lang === 'vi' ? 'dia-diem-an-uong-vui-choi/' : 'place-to-eat-drink-entertain/'
          }`}
        >
          <FormattedMessage {...message.topPlaces} />
        </BlogItem>
      ),
    },
  ];
  return (
    <Dropdown placement="bottomRight" menu={{ items: items }}>
      <DropdownText>
        <FormattedMessage {...message.sharingConner} />
        <DownOutlined />
      </DropdownText>
    </Dropdown>
  );
};

export default BlogDropDown;
