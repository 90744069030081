import React, { FC } from 'react';

interface IProps {
  isActive: boolean;
}

export const OrderIcon: FC<IProps> = (props) => {
  const { isActive } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2989 3.22656C17.9616 3.42455 19.7985 4.77744 19.7985 9.80406V16.4036C19.7985 20.8032 18.6986 23.0031 13.199 23.0031H6.5995C1.09992 23.0031 0 20.8032 0 16.4036V9.80406C0 4.78844 1.83686 3.42455 5.49958 3.22656"
        fill={isActive ? '#4CAF50' : 'none'}
        stroke={isActive ? 'none' : '#424242'}
      />
      <path
        d="M5.49609 12.2188H13.1955"
        stroke={isActive ? 'white' : '#424242'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.49609 16.6211H10.3137" stroke={isActive ? 'white' : '#424242'} strokeLinejoin="round" />
      <path
        d="M7.69593 5.39967H12.0956C14.2954 5.39967 14.2954 4.29975 14.2954 3.19983C14.2954 1 13.1955 1 12.0956 1H7.69593C6.59601 1 5.49609 1 5.49609 3.19983C5.49609 5.39967 6.59601 5.39967 7.69593 5.39967Z"
        fill={isActive ? '#4CAF50' : 'none'}
        stroke={isActive ? 'white' : '#424242'}
        strokeWidth={isActive ? '0.5' : '1'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FavoriteIcon: FC<IProps> = (props) => {
  const { isActive } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.997">
        <path
          d="M12.9326 21.8989C12.478 22.0337 11.994 22.0337 11.5394 21.8989C8.28093 20.7865 1.00003 16.1461 1.00003 8.28091C0.997662 7.45826 1.15739 6.6432 1.47009 5.8823C1.78279 5.12139 2.24233 4.42955 2.82247 3.84628C3.40261 3.26302 4.09197 2.79976 4.85118 2.48297C5.61039 2.16618 6.42457 2.00206 7.24722 2C8.21778 1.99953 9.17488 2.22704 10.0414 2.6642C10.9079 3.10137 11.6596 3.73596 12.236 4.51686C13.0199 3.46141 14.1174 2.68087 15.3717 2.28686C16.626 1.89286 17.9727 1.90557 19.2193 2.32319C20.4659 2.7408 21.5486 3.54193 22.3124 4.61199C23.0762 5.68205 23.4821 6.96624 23.472 8.28091C23.472 16.1461 16.1911 20.7865 12.9326 21.8989Z"
          fill={isActive ? '#4CAF50' : 'none'}
          stroke={isActive ? 'none' : '#424242'}
        />
      </g>
    </svg>
  );
};

export const ShopIcon: FC<IProps> = (props) => {
  const { isActive } = props;
  return isActive ? (
    <svg width="21" height="21" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.00781 11.2227V15.7127C3.00781 20.2027 4.80781 22.0027 9.29781 22.0027H14.6878C19.1778 22.0027 20.9778 20.2027 20.9778 15.7127V11.2227"
        fill="#4CAF50"
      />
      <path
        d="M3.00781 11.2227V15.7127C3.00781 20.2027 4.80781 22.0027 9.29781 22.0027H14.6878C19.1778 22.0027 20.9778 20.2027 20.9778 15.7127V11.2227"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0019 12C12.4259 12.0081 12.8468 11.9251 13.236 11.7566C13.6252 11.5881 13.9738 11.3381 14.2582 11.0234C14.5425 10.7087 14.7561 10.3367 14.8844 9.93242C15.0127 9.52816 15.0527 9.10107 15.0019 8.68L14.3419 2H9.67186L9.00186 8.68C8.951 9.10107 8.99108 9.52816 9.11937 9.93242C9.24767 10.3367 9.4612 10.7087 9.74556 11.0234C10.0299 11.3381 10.3785 11.5881 10.7677 11.7566C11.157 11.9251 11.5778 12.0081 12.0019 12Z"
        fill="#4CAF50"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3069 12C18.7734 12.0096 19.2365 11.9188 19.6648 11.7339C20.0932 11.5489 20.4768 11.2741 20.7897 10.928C21.1026 10.5819 21.3375 10.1726 21.4785 9.72782C21.6195 9.28304 21.6633 8.81316 21.6069 8.35L21.3269 5.6C20.9669 3 19.9669 2 17.3469 2H14.2969L14.9969 9.01C15.0972 9.82298 15.4869 10.5726 16.0948 11.1217C16.7026 11.6708 17.4879 11.9826 18.3069 12Z"
        fill="#4CAF50"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.64029 12C6.45862 11.984 7.24353 11.6724 7.85008 11.1228C8.45663 10.5733 8.84387 9.8228 8.94029 9.01L9.16029 6.8L9.64029 2H6.59029C3.97029 2 2.97029 3 2.61029 5.6L2.34029 8.35C2.28389 8.81316 2.32766 9.28304 2.46865 9.72782C2.60963 10.1726 2.84453 10.5819 3.15744 10.928C3.47035 11.2741 3.85398 11.5489 4.28234 11.7339C4.7107 11.9188 5.1738 12.0096 5.64029 12Z"
        fill="#4CAF50"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9993 16.9982C11.627 16.996 11.3131 16.9799 10.9874 17.0882C10.6617 17.1965 10.3657 17.3792 10.123 17.6219C9.88027 17.8646 9.69752 18.1606 9.58925 18.4863C9.48097 18.812 9.45016 19.1585 9.49926 19.4982V21.9982H14.4993V19.4982C14.5484 19.1585 14.5175 18.812 14.4093 18.4863C14.301 18.1606 14.1182 17.8646 13.8755 17.6219C13.6328 17.3792 13.3369 17.1965 13.0112 17.0882C12.6854 16.9799 12.498 16.999 11.9993 16.9982Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg width="20" height="20" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.00977 11.2227V15.7127C3.00977 20.2027 4.80977 22.0027 9.29977 22.0027H14.6898C19.1798 22.0027 20.9798 20.2027 20.9798 15.7127V11.2227"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0019 12C12.4259 12.0081 12.8468 11.9251 13.236 11.7566C13.6252 11.5881 13.9738 11.3381 14.2582 11.0234C14.5425 10.7087 14.7561 10.3367 14.8844 9.93242C15.0127 9.52816 15.0527 9.10107 15.0019 8.68L14.3419 2H9.67186L9.00186 8.68C8.951 9.10107 8.99108 9.52816 9.11937 9.93242C9.24767 10.3367 9.4612 10.7087 9.74556 11.0234C10.0299 11.3381 10.3785 11.5881 10.7677 11.7566C11.157 11.9251 11.5778 12.0081 12.0019 12V12Z"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3069 12C18.7734 12.0096 19.2365 11.9188 19.6648 11.7339C20.0932 11.5489 20.4768 11.2741 20.7897 10.928C21.1026 10.5819 21.3375 10.1726 21.4785 9.72782C21.6195 9.28304 21.6633 8.81316 21.6069 8.35L21.3269 5.6C20.9669 3 19.9669 2 17.3469 2H14.2969L14.9969 9.01C15.0972 9.82298 15.4869 10.5726 16.0948 11.1217C16.7026 11.6708 17.4879 11.9826 18.3069 12V12Z"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.64029 12C6.45862 11.984 7.24353 11.6724 7.85008 11.1228C8.45663 10.5733 8.84387 9.8228 8.94029 9.01L9.16029 6.8L9.64029 2H6.59029C3.97029 2 2.97029 3 2.61029 5.6L2.34029 8.35C2.28389 8.81316 2.32766 9.28304 2.46865 9.72782C2.60963 10.1726 2.84453 10.5819 3.15744 10.928C3.47035 11.2741 3.85398 11.5489 4.28234 11.7339C4.7107 11.9188 5.1738 12.0096 5.64029 12V12Z"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0012 16.9982C11.6289 16.996 11.315 16.9799 10.9893 17.0882C10.6636 17.1965 10.3676 17.3792 10.1249 17.6219C9.88223 17.8646 9.69948 18.1606 9.5912 18.4863C9.48293 18.812 9.45211 19.1585 9.50121 19.4982V21.9982H14.5012V19.4982C14.5503 19.1585 14.5195 18.812 14.4112 18.4863C14.3029 18.1606 14.1202 17.8646 13.8775 17.6219C13.6348 17.3792 13.3388 17.1965 13.0131 17.0882C12.6874 16.9799 12.5 16.999 12.0012 16.9982Z"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ProfileIcon: FC<IProps> = (props) => {
  const { isActive } = props;
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2076 9.96405C10.0969 9.95396 9.98548 9.95396 9.87476 9.96405C8.70086 9.92044 7.59097 9.41787 6.78378 8.56443C5.97659 7.71098 5.53658 6.57484 5.55839 5.40034C5.5802 4.22584 6.06208 3.10681 6.90039 2.28392C7.7387 1.46102 8.86649 1 10.0412 1C11.2159 1 12.3437 1.46102 13.182 2.28392C14.0203 3.10681 14.5022 4.22584 14.524 5.40034C14.5458 6.57484 14.1058 7.71098 13.2986 8.56443C12.4914 9.41787 11.3815 9.92044 10.2076 9.96405Z"
        stroke={isActive ? '#4CAF50' : '#424242'}
        fill={isActive ? '#4CAF50' : 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16376 13.6891C2.72276 15.3231 2.72276 17.9861 5.16376 19.61C6.69015 20.521 8.43461 21.002 10.2122 21.002C11.9898 21.002 13.7342 20.521 15.2606 19.61C17.7016 17.976 17.7016 15.3131 15.2606 13.6891C13.7326 12.7829 11.9887 12.3047 10.2122 12.3047C8.43562 12.3047 6.69181 12.7829 5.16376 13.6891V13.6891Z"
        stroke={isActive ? '#4CAF50' : '#424242'}
        fill={isActive ? '#4CAF50' : 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CartIcon: FC<IProps> = (props) => {
  const { isActive } = props;
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.24805 6.66378V5.6934C5.25981 4.55916 5.67497 3.46615 6.41918 2.61012C7.1634 1.75409 8.18805 1.19095 9.30963 1.02157C9.93488 0.960142 10.5661 1.03027 11.1626 1.22743C11.7591 1.4246 12.3078 1.74444 12.7732 2.16639C13.2387 2.58834 13.6107 3.10305 13.8653 3.67741C14.1199 4.25177 14.2515 4.87306 14.2516 5.50132V6.88186"
        stroke={isActive ? '#4CAF50' : '#424242'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75305 21.0016H12.7554C16.777 21.0016 17.4972 19.3909 17.7073 17.4302L18.4576 11.4278C18.7277 8.98687 18.0275 6.99609 13.7558 6.99609H5.75266C1.48099 6.99609 0.780718 8.98687 1.05082 11.4278L1.80112 17.4302C2.0112 19.3909 2.73148 21.0016 6.75305 21.0016Z"
        stroke={isActive ? '#4CAF50' : '#424242'}
        fill={isActive ? '#4CAF50' : 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2383 10.9961H13.2456"
        stroke={isActive ? 'white' : '#424242'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.24414 10.9961H6.25144"
        stroke={isActive ? 'white' : '#424242'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
