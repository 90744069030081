import { defineMessages } from 'react-intl';

export const scope = 'app.components.AlcoholPopup';

export default defineMessages({
  alcoholPopupTitle: {
    id: `${scope}.alcoholPopupTitle`,
    defaultMessage: 'Xác nhận tuổi của bạn',
  },
  alcoholPopupDescription: {
    id: `${scope}.alcoholPopupDescription`,
    defaultMessage: 'Trang này chứa sản phẩm có cồn. Vui lòng xác nhận bạn đã đủ 18 tuổi.',
  },
  doNotShowAgain: {
    id: `${scope}.doNotShowAgain`,
    defaultMessage: 'Không hiển thị lại thông tin này',
  },
  underEighTeen: {
    id: `${scope}.underEighTeen`,
    defaultMessage: 'Dưới 18 tuổi',
  },
  overEighTeen: {
    id: `${scope}.overEighTeen`,
    defaultMessage: 'Đã đủ 18 tuổi',
  },
});
