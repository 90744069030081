/*
 * GroupsPage Messages
 *
 * This contains all the text for the GroupsPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.GroupsPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Quản lý nhóm',
  },
  groups: {
    id: `${scope}.groups`,
    defaultMessage: 'Nhóm',
  },
  members: {
    id: `${scope}.members`,
    defaultMessage: 'Thành viên',
  },
  permissions: {
    id: `${scope}.permissions`,
    defaultMessage: 'Quyền',
  },
  searchGroup: {
    id: `${scope}.searchGroup`,
    defaultMessage: 'Tìm theo tên nhóm',
  },
  filterByPermissions: {
    id: `${scope}.filterByPermissions`,
    defaultMessage: 'Lọc theo quyền',
  },
  createGroup: {
    id: `${scope}.createGroup`,
    defaultMessage: 'Tạo nhóm',
  },
});
