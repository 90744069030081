import { Tabs } from 'antd';
import messagesOverview from 'containers/DashboardPage/messages';
import messagesGroupManagementPage from 'containers/GroupsPage/messages';
import messagesItemAnalysis from 'containers/ItemAnalysisPage/messages';
import messagesOrderPage from 'containers/OrdersPage/messages';
import messagesPendingApproval from 'containers/PendingApprovalPage/messages';
import messagesPendingOrderHistory from 'containers/PendingOrdersHistoryPage/messages';
import messagesUserManagementPage from 'containers/UserManagementPage/messages';
import messagesCompanyInfoPage from 'containers/CompanyInfoPage/messages';
import messagesStoreInfoPage from 'containers/StoreInfoPage/messages';
import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import translations from 'translations';
import { Viewport } from 'components/ViewportProvider';

import { viewPermissions } from 'utils/permissionMap';

interface TabPagesType extends RouteComponentProps {
  permissions: string[];
  isPendingApproval: boolean;
}

const { TabPane } = Tabs;

const pages = [
  {
    id: 'ordersPage',
    path: '/orders',
    type: 'order',
    text: translations(messagesOrderPage.header),
    mobileHidden: false,
  },
  {
    id: 'pendingApprovalPage',
    path: '/orders-pending-approval',
    type: 'order',
    text: translations(messagesPendingApproval.header),
    mobileHidden: false,
  },
  {
    id: 'pendingOrderHistoryPage',
    path: '/pending-orders-history',
    type: 'order',
    text: translations(messagesPendingOrderHistory.header),
    mobileHidden: false,
  },
  {
    id: 'usersManagementPage',
    path: '/user-management',
    type: 'management',
    text: translations(messagesUserManagementPage.header),
    mobileHidden: false,
  },
  {
    id: 'groupManagementPage',
    path: '/group-management',
    type: 'management',
    text: translations(messagesGroupManagementPage.header),
    mobileHidden: false,
  },
  {
    id: 'overview',
    path: '/overview',
    type: 'overviewReport',
    text: translations(messagesOverview.header),
    mobileHidden: false,
  },
  {
    id: 'itemAnalysis',
    path: '/item-analysis',
    type: 'overviewReport',
    text: translations(messagesItemAnalysis.header),
    mobileHidden: true,
  },
  {
    id: 'companyInfo',
    path: '/company-info',
    type: 'companyAndStore',
    text: translations(messagesCompanyInfoPage.header),
    mobileHidden: false,
  },
  {
    id: 'storeInfo',
    path: '/store-info',
    type: 'companyAndStore',
    text: translations(messagesStoreInfoPage.header),
    mobileHidden: false,
  },
];

const TabPages: FC<TabPagesType> = (props) => {
  const { history, location, isPendingApproval, permissions: userPermissions } = props;

  const canAccessView = useCallback(
    (path: string): boolean => {
      const permissions: string[] = viewPermissions[path];
      if (permissions) {
        let permissionCounter = 0;
        for (const permission of permissions) {
          if (userPermissions.indexOf(permission) > -1) {
            permissionCounter += 1;
          }
        }
        if (permissionCounter !== permissions.length) {
          return false;
        }
      }
      return true;
    },
    [userPermissions],
  );
  const currentTabPages = useMemo(() => {
    const currentPath = location.pathname;
    const currentPage = pages.find((page) => page.path === currentPath);
    if (currentPage) {
      const pagesInSameType = pages.filter((page) => page.type === currentPage.type);
      if (currentPage.type === 'order') {
        if (!isPendingApproval) {
          return pagesInSameType.filter((page) => page.path === '/orders');
        }
        const isShowPendingApproval = canAccessView('/orders-pending-approval');
        if (isShowPendingApproval) {
          const pendingOrdersHistoryIndex = pagesInSameType.findIndex(
            (page) => page.path === '/pending-orders-history',
          );
          pagesInSameType.splice(pendingOrdersHistoryIndex, 1);
          return pagesInSameType;
        }
        const isShowPendingOrdersHistory = canAccessView('/pending-orders-history');
        if (isShowPendingOrdersHistory) {
          const pendingApprovalIndex = pagesInSameType.findIndex((page) => page.path === '/orders-pending-approval');
          pagesInSameType.splice(pendingApprovalIndex, 1);
          return pagesInSameType;
        }
        return pagesInSameType.filter((page) => page.path === '/orders');
      }
      return pagesInSameType;
    }
    return [];
  }, [isPendingApproval, location.pathname, canAccessView]);

  const handleChangeTab = (key) => {
    const targetPage = pages.find((page) => page.id === key);
    if (targetPage) {
      history.push(targetPage?.path);
    }
  };
  const currentPageActive = useMemo(() => {
    const currentPath = location.pathname;
    const currentPage = currentTabPages.find((page) => page?.path === currentPath);
    return currentPage ? currentPage.id : '';
  }, [location, currentTabPages]);
  return (
    <>
      <Viewport.Consumer>
        {({ width }) =>
          width > 767
            ? !!currentTabPages.length && (
                <Tabs
                  className="tab-pages-wrapper"
                  activeKey={currentPageActive}
                  onChange={handleChangeTab}
                  tabBarGutter={16}
                  items={currentTabPages.map((page) => ({
                    key: page.id,
                    label: page.text,
                  }))}
                />
              )
            : !!currentTabPages.length && (
                <Tabs
                  className="tab-pages-wrapper"
                  activeKey={currentPageActive}
                  onChange={handleChangeTab}
                  tabBarGutter={16}
                >
                  {currentTabPages.map((page) => !page.mobileHidden && <TabPane tab={page.text} key={page.id} />)}
                </Tabs>
              )
        }
      </Viewport.Consumer>
    </>
  );
};

export default withRouter(TabPages);
