import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, MessagePayload, onMessage, isSupported, Messaging } from 'firebase/messaging';
import { NotificationMessageData, NotificationMessageNavigationType } from 'types/schema';

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

let messaging: Messaging;

isSupported().then((value) => {
  if (value) {
    messaging = getMessaging(firebaseApp);
  }
});

export const requestFCMToken = (): Promise<string> =>
  new Promise((resolve, reject) => {
    getToken(messaging, { vapidKey: process.env.FIREBASE_VAPID_KEY })
      .then((token: string) => {
        if (!token) {
          reject('No registration token available. Request permission to generate one.');
        }
        resolve(token);
      })
      .catch((err) => reject(err));
  });

export const onMessageListener = (buyerId: string, storeId: string): Promise<MessagePayload> =>
  new Promise((relsove, reject) => {
    try {
      onMessage(messaging, (payload) => {
        if (payload.notification) {
          const notification = new Notification(payload.notification.title || 'You have a new notification', {
            body: payload.notification.body,
            icon: '/favicon.ico',
          });
          notification.onclick = (event) => {
            notification.close();
            const data = payload.data as unknown as NotificationMessageData & {
              messageId: string; // Kamereo notification ID
            };
            if (!data) return;

            if (data.navType && data.navValue) {
              switch (data.navType) {
                case NotificationMessageNavigationType.Category:
                  window.open(`/market?category=${encodeURIComponent(data.navValue)}`);
                  break;
                case NotificationMessageNavigationType.Product:
                  window.open(`/products/${data.navValue}`);
                  break;
                case NotificationMessageNavigationType.Tag:
                  window.open(`/market?tags=${data.navValue}`);
                  break;
                case NotificationMessageNavigationType.Url:
                  window.open(`${data.navValue}`);
                  break;
                default:
              }
              return;
            }

            if (data.orderId) {
              let url = `/orders/${data.orderId}`;
              if (data.messageId) {
                url += `?messageId=${data.messageId}`;
              }
              window.open(url);
            }
            event.preventDefault();
          };
        }
      });
    } catch (error) {
      reject(error);
    }
  });
