


import React, { FC } from 'react';

interface IProps {
  color?: string;
}

export const ArrowDownSwitchStoreIcon: FC<IProps> = ({ color = '#ffffff' }: IProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9201 8.94995L13.4001 15.47C13.028 15.8398 12.5247 16.0473 12.0001 16.0473C11.4755 16.0473 10.9722 15.8398 10.6001 15.47L4.08008 8.94995" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
