import { Modal as AModal } from 'antd';
import { setAlcoholAgeState, setAlcoholState } from 'containers/MainLayout/actions';
import { FC, default as React } from 'react';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import translations from 'translations';
import messages from './messages';

import { connect } from 'react-redux';

interface IDispatchProps {
  setAlcoholState: (data: boolean) => void;
  gotoHomePage: () => void;
  setAlcoholAgeState: (data: boolean) => void;
}

const Modal = styled(AModal)`
  max-width: 441px !important;
  width: 100% !important;
  padding: 0 16px;
  .ant-modal-footer {
    display: flex;
    button {
      width: 100%;
      height: 38px;
    }
  }
`;
const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;
const Description = styled.div`
  padding: 12px 0px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
`;
interface OwnProps {
  open: boolean;
  isUnder18: boolean;
}
type Props = OwnProps & IDispatchProps;

const AlcoholPopup: FC<Props> = ({ open, setAlcoholState, gotoHomePage, setAlcoholAgeState }) => {
  const isUnder18FromLocalStorage = window.localStorage.getItem('isUnder18')
    ? window.localStorage.getItem('isUnder18') === 'true'
    : true;
  const isUnder18FromSessionStorage = window.sessionStorage.getItem('isUnder18')
    ? window.sessionStorage.getItem('isUnder18') === 'true'
    : true;
  const isLogin = window.localStorage.getItem('token');
  const isOpenPopup = isLogin ? open && isUnder18FromLocalStorage : open && isUnder18FromSessionStorage;

  return (
    <Modal
      open={isOpenPopup}
      closeIcon={false}
      okText={translations(messages.overEighTeen)}
      cancelText={translations(messages.underEighTeen)}
      centered={true}
      onOk={() => {
        if (isLogin) {
          window.localStorage.setItem('isUnder18', 'false');
        } else {
          window.sessionStorage.setItem('isUnder18', 'false');
        }
        setAlcoholState(false);
      }}
      onCancel={() => {
        if (isLogin) {
          window.localStorage.setItem('isUnder18', 'true');
        } else {
          window.sessionStorage.setItem('isUnder18', 'true');
        }
        setAlcoholState(false);
        gotoHomePage();
      }}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Title>{translations(messages.alcoholPopupTitle)}</Title>
      <Description>{translations(messages.alcoholPopupDescription)}</Description>
    </Modal>
  );
};

function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return {
    setAlcoholState: (data) => dispatch(setAlcoholState(data)),
    gotoHomePage: () => dispatch(push('/')),
    setAlcoholAgeState: (data) => dispatch(setAlcoholAgeState(data)),
  };
}
const withConnect = connect(() => {}, mapDispatchToProps);

export default withConnect(AlcoholPopup);
