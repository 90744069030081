/*
 * StoreInfoPage Messages
 *
 * This contains all the text for the StoreInfoPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.StoreInfoPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Cửa hàng',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Tên',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Địa chỉ',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Số Điện Thoại',
  },
  editProfile: {
    id: `${scope}.editProfile`,
    defaultMessage: 'Chỉnh sửa thông tin',
  },
  deliveryMessages: {
    id: `${scope}.deliveryMessages`,
    defaultMessage: 'Ghi chú giao hàng',
  },
  addMore: {
    id: `${scope}.addMore`,
    defaultMessage: 'Thêm',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Lưu',
  },
  editStoreInfo: {
    id: `${scope}.editStoreInfo`,
    defaultMessage: 'Chỉnh sửa chi nhánh',
  },
  saveDeliveryNoteSuccess: {
    id: `${scope}.saveDeliveryNoteSuccess`,
    defaultMessage: 'Cập nhật ghi chú giao hàng thàng công',
  },
  deleteDeliveryNoteMessage: {
    id: `${scope}.deleteDeliveryNoteMessage`,
    defaultMessage: 'Bạn có chắc muốn xoá ghi chú này?',
  },
  storeImage: {
    id: `${scope}.storeImage`,
    defaultMessage: 'Hình ảnh cửa hàng',
  },
});
