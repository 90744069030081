import { Input as AInput, Dropdown, Tooltip } from 'antd';
import TrackingInput from 'components/TrackingComponents/TrackingInput';
import React from 'react';
import { Link, LinkProps, NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import media from 'utils/mediaStyle';
import { CategoryContainer } from '../../components/CategoryNav/desktopNewDesign';
import defaultLogo from '../Image/noIMG.svg';
import SVGIcon from '../SVGIcon/SVGIcon';
import ArrowDown from './ArrowDown';
export const KamereoLogo = styled(ReactSVG)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 165px;
  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  @media (max-width: 490px) {
    margin: 13.5px 0;
    & > div {
      height: 23px;
    }
  }
`;

export const AntdInput = styled(AInput)`
  & .ant-input-search-icon {
    font-size: 18px;
    opacity: 0;
  }
  & .search-icon {
    position: absolute;
  }
`;

export const VerticalLine = styled.span`
  width: 1px;
  height: 50%;
  background: #808089;
  opacity: 0.25;
  margin: 0 10px;
`;

export const NavToggleIcon = styled(SVGIcon)`
  width: 53px;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const LinkText = styled(Link)`
  color: inherit;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const TopNavBadge = styled.div`
  position: absolute;
  top: 5px;
  right: 2px;
  width: 20px;
  height: 20px;
  background: #ff4852;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  border: 1px solid #ffffff;
  z-index: 10;
  @media (max-width: 576px) {
    top: 0;
    right: 2px;
  }
`;

export const RedDotBadge = styled.div`
  position: absolute;
  top: 16px;
  right: 10px;
  width: 14px;
  height: 14px;
  background: #ff4852;
  border-radius: 50%;
  border: 1px solid #ffffff;
  z-index: 10;
  @media (max-width: 767px) {
    top: 8px;
    right: 4px;
  }
`;

export const TopNavItem = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 46pt;
  position: relative;
  ${media.sm`
    padding: 10pt;
    min-width: 50pt;
  `};
`;

interface TopNavLinkItemProps {
  isLogoNav?: boolean;
}

export const TopNavLinkItem = styled(({ isLogoNav, ...props }: LinkProps & { isLogoNav: boolean }) => (
  <Link {...props} />
))`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ isLogoNav }: TopNavLinkItemProps) => (isLogoNav === true ? '10pt' : '10pt')};
  cursor: pointer;
  position: relative;
  height: 100%;
  color: #292d32;

  :hover {
    color: #43a047;
  }

  @media (max-width: 490px) {
    padding: ${({ isLogoNav }: TopNavLinkItemProps) => (isLogoNav === true ? '0' : '0pt 6pt')};
  }
  ${SVGIcon} {
    @media (max-width: 767px) {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(215deg) brightness(103%) contrast(102%);
    }
  }
  &.no-padding {
    padding: 0;
  }
`;

export const TopNavButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10pt;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  height: 100%;
  &:hover {
    color: #4caf50;
  }
  @media (max-width: 767px) {
    color: #ffffff;
    padding: 12pt 8pt;
    &:hover {
      color: #ffffff;
    }
  }
  &.contact-us {
    padding: 10pt 0 10pt 10pt;
  }
  button {
    font-size: 16px !important;
  }
`;

export const TopNavButtonHiddenOnMobile = styled(TopNavButton)`
  display: flex;
  height: 100%;
  font-weight: 700;
  @media (max-width: 1076px) {
    display: none;
  }
  & > .ant-btn {
    font-weight: 700;
    background: #4caf50;
    padding: 8px 16px;
    height: auto;
    border: none;
    border-radius: 8px !important;
    line-height: 25.6px;

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const ProfileInfo = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TopNavMenuItem = styled(Dropdown)<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
`;

export const Avatar = styled.img`
  width: 28pt;
  height: 28pt;
  border-radius: 4px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  ${media.xl`
    width: 100%;
    max-width: 800px;
  `};
  & > .ant-input-affix-wrapper {
    border-radius: 8px !important;
  }
`;

export const alternativeImage = (name) => `https://ui-avatars.com/api/?name=${name}&size=512`;

export const ProfileInfoContainer = ({
  user,
  ...props
}: {
  user: { name: string; title: string; imageUrl: string; email: string };
}) => (
  <ProfileInfo {...props}>
    <Avatar src={user.imageUrl || alternativeImage(user.name) || defaultLogo} />
    <ArrowDown />
  </ProfileInfo>
);

export const MenuLabel = styled.div`
  flex: 1;
  font-size: 14px;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  width: 100%;
  ${MenuLabel} {
    color: ${({ color }) => color};
  }
  ${SVGIcon} {
    path {
      fill: ${({ color }) => color};
    }
  }
  ${SVGIcon} + ${MenuLabel} {
    margin-left: 10pt;
  }
`;

export const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  flex-wrap: nowrap;
  padding: 8pt;
  margin: 0 !important;
  width: 100%;
  ${MenuLabel} {
    color: ${({ color }) => color};
  }
  ${SVGIcon} {
    path {
      fill: ${({ color }) => color};
    }
  }
  ${SVGIcon} + ${MenuLabel} {
    margin-left: 10pt;
  }
`;

export const TopNavWrapper = styled.header`
  color: rgba(0, 0, 0, 0.87);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #f9fafc;
  ${media.md`
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.10));
    gap: 10px;
    background: #fcfffc;
    flex-wrap: nowrap;
  `};
`;

export const LeftNav = styled.nav`
  display: flex;
  align-items: center;
  ${media.md`
    order: 1;
  `};
`;

export const MiddleNav = styled.nav`
  display: flex;
  order: 3;
  width: 100%;
  padding: 0px 10px 10px;
  ${media.md`
    order: 2;
    width: auto;
    padding: unset;
    flex: 1;
`};
`;

export const RightNav = styled.nav`
  display: flex;
  padding: 0 7pt 0 0;
  margin-left: auto;
  align-items: center;
  order: 2;
  gap: 26px;
  ${media.md`
    order: 3;
  `};
  &.login {
    gap: 32px;
  }
  @media (max-width: 767px) {
    gap: unset !important;
  }
  @media (min-width: 992px) {
    padding: 0 7pt 0 13px;
    &.login {
      padding-left: 57px;
    }
  }
`;

export const EnvIndicator = styled.div`
  line-height: 33pt;
  text-transform: uppercase;
  font-size: 15pt;
  color: white;
  background: rgb(254, 80, 67);
  height: 100%;
  padding: 2pt 10pt;
  display: none;
  ${media.md`
    display: block
  `};
`;

export const TopNavTopContent = styled.div`
  display: none;
  padding: 4px 10pt;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  max-width: 1200px;
  margin: 0 auto;
  ${media.md`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `};
`;

export const TopNavTopContentWrapper = styled.div`
  display: none;
  background: #43a047;
  height: 36px;
  ${media.md`
    display: block;
  `};
`;

export const TopNavTopContentLeft = styled.div`
  display: flex;
  align-items: center;
  margin-left: -8px;
  a {
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }
`;
export const TopNavTopContentRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: -8px;
  min-height: 28px;
  a {
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }
  .top-nav-item {
    position: relative;
    padding: 0 10px;
    &:not(:last-child):before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      width: 1px;
      height: 12px;
      background: rgba(255, 255, 255, 0.5);
    }
    & > img {
      display: flex;
      position: relative;
      top: 1px;
    }
  }
  .language-dropdown {
    cursor: pointer;
  }
`;

export const StyledLink = styled(NavLink)<any>`
  display: flex;
  gap: 9pt;
  align-items: center;
  position: relative;
  span {
    color: #424242;
  }
  &.active,
  &:hover {
    span {
      color: #43a047;
    }
    div div svg {
      filter: invert(53%) sepia(9%) saturate(3454%) hue-rotate(73deg) brightness(99%) contrast(68%);
    }
  }
  &:first-child:hover ~ ${CategoryContainer} {
    display: block;
  }
  @media (max-width: 1024px) {
    position: relative;
    flex-direction: column;
    gap: 3px;
    margin: 0 auto;
    width: fit-content;
    & > span {
      text-align: center;
      font-size: 12px;
    }
    &:hover {
      span {
        color: #424242;
      }
      div div svg {
        filter: unset;
      }
    }
    &.active {
      &:before {
        content: '';
        background: #43a047;
        width: 55px;
        height: 2px;
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        ${({ cart }) =>
          cart &&
          `
          top: -13px;
        `}
      }
      span {
        color: #43a047;
      }
    }
    ${({ cart }) =>
      cart &&
      `
      gap: 0px;
      `}
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
  padding: 0 8px;
  &:not(:last-child):before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 1px;
    height: 12px;
    background: rgba(255, 255, 255, 0.5);
  }
`;

export const TopNavTooltip = styled(Tooltip)``;

export const TopNavContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  ${media.md`
    gap: 10pt;
    flex-wrap: nowrap;
    height: 70px;
  `};
  @media (max-width: 767px) {
    background: #4caf50;
  }
  ${CategoryContainer} {
    top: 99.5px;
    margin-left: 13px;
    border-top: 1px solid rgba(158, 158, 158, 0.2);
  }
`;

export const CustomSearchInput = styled(TrackingInput)`
  .ant-input {
    background: none;
    border-radius: 8px;
    height: 30px;
    border: 1px solid #bdbdbd;
    max-width: 800px;
    @media (max-width: 767px) {
      background: #ffffff;
    }
  }
  flex: 1;
`;
export const SwitchStoreContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 8px 0 10px;
  & svg {
    width: 14px;
  }
`;

export const SwitchRegionContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1px 6px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.15);
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
  & svg {
    width: 14px;
  }
`;

export const SwitchStoreMobileContainer = styled.div`
  padding: 0 14px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    gap: 4px;
    p {
      font-size: 16px;
    }
    &:last-child {
      p {
        font-size: 14px;
      }
    }
  }
  & svg {
    width: 20px;
  }
`;

export const SwitchStoreText = styled.p`
  display: flex;
  align-items: center;
`;

export const StoreName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 165px;
  @media (max-width: 767px) {
    max-width: 130px;
  }

  @media (max-width: 425px) {
    max-width: 80px;
  }

  @media (max-width: 375px) {
    max-width: 60px;
  }

  @media (max-width: 320px) {
    max-width: 20px;
  }
`;

export const SignUpButton = styled.div`
  padding: 7px 16px;
  background: #ffffff;
  border-radius: 8px !important;
  color: #4caf50;
  font-size: 15px;
`;
export const SignInSignUpWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10pt;
  @media (max-width: 767px) {
    display: none;
  }
`;
